import {
  Button,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import ContentPreview from "../components/ContentPreview"
import Layout from "../components/Layout"

export const query = graphql`
  {
    posts: allContentfulBlogPost(sort: { fields: date, order: DESC }) {
      nodes {
        title
        image {
          gatsbyImageData(width: 400, height: 250, placeholder: BLURRED)
        }
        summary {
          summary
        }
        tags
        slug
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  sectionSeperator: {
    marginBottom: "8rem",
  },
  sectionSeperatorShadowed: {
    borderRadius: "100%",
    margin: "18rem auto 8rem",
    boxShadow: "0 14px 0px -6px #660000",
    width: "40%",
    height: "1.4rem",
  },
  pageTitle: {
    fontSize: "3.2rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "8rem",
    marginTop: "8rem",

    [theme.breakpoints.down("sm")]: {
      fontSize: "2.4rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.6rem",
    },
  },
  sectionTitle: {
    fontSize: "3.2rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.4rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.6rem",
    },
  },
  button: {
    fontSize: "1.8rem",
    marginTop: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
    "&:hover": {
      color: "#fff",
    },
  },
}))

const Blog = () => {
  const pageMeta = {
    title: "المدونة",
    description: "مدونة وآخر أخبار الامن المعلوماتي",
    keywords: [
      "أمن المعلومات",
      "حماية",
      "أخبار أمنية",
      "اختراقات",
      "ثغرات",
      "مدونة",
      "مقال",
      "امن",
    ],
  }
  const data = useStaticQuery(query)
  const classes = useStyles()
  const matches960 = useMediaQuery("(max-width: 960px)")

  return (
    <Layout pageMeta={pageMeta}>
      <Grid container direction="column">
        <Grid item align="center">
          <Typography className={classes.pageTitle}>المدونة الأمنية</Typography>
        </Grid>

        {/* blog Posts */}
        <Grid item container direction="column">
          <Grid item align={matches960 ? "center" : "right"}>
            <Typography className={classes.sectionTitle} paragraph>
              آخر الأخبار
            </Typography>
          </Grid>
          <Grid item>
            <ContentPreview
              direction="row"
              isButton
              content="blogPosts"
              data={data}
            />
          </Grid>
          <Grid item className={classes.sectionSeperatorShadowed} />
        </Grid>

        {/* Call to action */}
        <Grid
          item
          container
          direction="column"
          justify="center"
          align="center"
          spacing={2}
        >
          <Grid item>
            <Typography
              className={classes.sectionTitle}
              style={{ marginBottom: "2rem" }}
            >
              لحماية بياناتك وبيانات مؤسستك
            </Typography>
          </Grid>

          <Grid item>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to="/contact"
              className={classes.button}
            >
              تواصل معنا
            </Button>
          </Grid>
          <Grid item className={classes.sectionSeperator} />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Blog
